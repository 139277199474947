import { FC, useState } from 'react';
import { useModel } from '@modern-js/runtime/model';
import { Button, Input, Message } from '@arco-design/web-react';
import { useRequest } from 'ahooks';
import KeywordsSelect from '../keywords-select';
import './index.scss';
import AdvancedWritingStore from '@/store/advancedWritingStore';
import useLocale from '@/hooks/useLocale';
import { AdvancedWritingKeywordsGenerate, queryOutlineWriting, updateOutlineWritingName } from '@/api/copilot_api';
import { useArticleId } from '@/hooks/article';
import AiWritingStore from '@/store/aiWritingStore';
import { MAX_ADVANCED_WRITING_KEYWORDS, MAX_USER_PROMPT_LEN } from '@/config/constant';

const Step1: FC<{
  onNextStep: () => void;
}> = ({ onNextStep }) => {
  const locale = useLocale();

  const [isGeneratingKeywords, setIsGeneratingKeywords] = useState(false);

  const [{ writingParams, enableRetrieval }, { setWritingParams }] = useModel(AdvancedWritingStore);

  const [{ advanceList }, { setAdvanceList, setAdTotalCount, setAdCurrentIndex }] = useModel(AiWritingStore);
  const [keywordsOptions, setKeywordsOptions] = useState<string[]>([]);

  const articleId = useArticleId();

  const generatekeywords = async () => {
    setIsGeneratingKeywords(true);
    try {
      const keywordsResp = await AdvancedWritingKeywordsGenerate({
        articleId,
        writingRequirements: writingParams.writingRequirements,
        selectedKeywords: writingParams.selectedKeywords,
        enableRetrieval,
      });
      setKeywordsOptions(keywordsResp.keywords || []);
      setWritingParams({ genre: keywordsResp.genre || '' });
    } catch (error) {
    } finally {
      setIsGeneratingKeywords(false);
    }
  };

  // 获取长文写作列表
  const { runAsync: runQueryOutlineWriting } = useRequest(queryOutlineWriting, {
    manual: true,
  });

  // 编辑长文写作列表项
  const { runAsync: runUpdateOutlineWritingName } = useRequest(updateOutlineWritingName, {
    manual: true,
  });

  return (
    <div>
      <div className="space-y-4 pb-6">
        <div className="space-y-3">
          <span>{locale.ad_writing_requirements}</span>
          <Input.TextArea
            className="rounded-lg min-h-52 resize-none p-4"
            value={writingParams.writingRequirements}
            onChange={value => {
              // if (value.length > MAX_USER_PROMPT_LEN) {
              //   Message.error(locale.ad_writing_writing_requirements_limit);
              //   return;
              // }
              setWritingParams({
                writingRequirements: value,
              });
            }}
            placeholder={locale.ad_writing_requirements_placeholder}
          />
        </div>
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <span>{locale.ad_writing_keywords}</span>
            <Button type="text" onClick={generatekeywords} loading={isGeneratingKeywords} disabled={!writingParams.writingRequirements.length}>
              {locale.ad_writing_generate_with_ai}
            </Button>
          </div>
          <KeywordsSelect
            value={writingParams.selectedKeywords}
            options={keywordsOptions}
            onChange={(value, options) => {
              if (value.length > MAX_ADVANCED_WRITING_KEYWORDS) {
                Message.info(locale.ad_writing_keywords_limit.replace('{limit}', 10));
                return;
              }
              setWritingParams({ selectedKeywords: value });
              setKeywordsOptions(options);
            }}
          />
        </div>
      </div>

      <div className="generate-outline-form-footer">
        <Button
          type="primary"
          className="w-full"
          onClick={async () => {
            // 更新侧边栏文章的标题
            const fItem = advanceList.find(item => item.articleId === articleId);
            if (!fItem?.title) {
              try {
                await runUpdateOutlineWritingName({ ...fItem, articleId, title: writingParams.writingRequirements });
                const res = await runQueryOutlineWriting({ lastId: articleId });
                const { articles = [], total = 0, page = 1 } = res;
                setAdvanceList(articles);
                setAdTotalCount(total);
                setAdCurrentIndex(page);
              } catch (error) {
                console.error(error);
              }
            }
            onNextStep();
          }}
          disabled={!writingParams.selectedKeywords.length || !writingParams.writingRequirements || isGeneratingKeywords}
        >
          {locale.ad_writing_next}
        </Button>
      </div>
    </div>
  );
};

export default Step1;
