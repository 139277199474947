/* eslint-disable complexity */
import { Button, Input, Message, Tooltip } from '@arco-design/web-react';
import { FC } from 'react';
import { IconArrowDown, IconArrowUp, IconMinus } from '@arco-design/web-react/icon';
import { IconArrowClockwise, IconTrash } from '@arco-iconbox/react-hkgai-government-icon';
import { Section as SectionType } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { IconPlus } from '@arco-iconbox/react-hkgai-fe-icon';
import { useModel } from '@modern-js/runtime/model';
import AdvancedWritingStore from '@/store/advancedWritingStore';
import useLocale from '@/hooks/useLocale';
import { SectionModifiedEvent } from '@/type/advanceWriting';
import { SECTION_TITLE_MAX_LEN } from '@/config/constant';

type SectionProps = {
  section: SectionType;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
  canAddSummary?: boolean;
  canAddSubSection?: boolean;
  canRefreshSection?: boolean;
  disabled?: boolean;

  // onChange?: (section: SectionType) => void;
  onRefreshSectionOutline?: (path: number[]) => void;
  onRefreshSection?: (path: number[]) => void;
  onRefreshSubSection?: (path: number[]) => void;
  onAddSubsSection?: (path: number[]) => void;
  onAddSummary?: (path: number[]) => void;
  onRemoveSummary?: (path: number[]) => void;
  onRefreshSummary?: (path: number[]) => void;
  onClickMoveUp?: (path: number[]) => void;
  onClickMoveDown?: (path: number[]) => void;
  onClickDelete?: (path: number[]) => void;
  onTitleChange?: (title: string, path: number[]) => void;
  path: number[];
};
const Section: FC<SectionProps> = ({
  section,
  canMoveUp,
  canMoveDown,
  canAddSummary,
  canAddSubSection,
  canRefreshSection,
  disabled,

  onRefreshSectionOutline,
  onRefreshSection,
  onRefreshSubSection,
  onAddSubsSection,
  onAddSummary,
  onRemoveSummary,
  onRefreshSummary,
  onClickMoveUp,
  onClickMoveDown,
  onClickDelete,
  onTitleChange,
  // onChange,
  path,
}) => {
  const locale = useLocale();

  const [{ sectionLoadingState }, { changeSectionModifiedTag }] = useModel(AdvancedWritingStore);

  const { isGeneratingContent, isGeneratingOutline, isGeneratingSummary, isSubSectionGeneratingContent, isSubSectionGeneratingOutline } = sectionLoadingState({ path }) || {};
  return (
    <div className="space-y-2">
      <div className="flex items-center space-x-3">
        <Input
          value={section.sectionTitle}
          className="flex-1 h-10 rounded-lg bg-white border-[#C4C4C4] text-xs"
          data-section-id={section.sectionId}
          onChange={value => {
            if (value.length > SECTION_TITLE_MAX_LEN) {
              Message.error(locale.ad_writing_section_title_limit.replace('{limit}', 20));
              return;
            }
            onTitleChange?.(value, path.slice());
            changeSectionModifiedTag({ path: path.slice(), event: SectionModifiedEvent.SectionOutlineModified });
          }}
          disabled={disabled || isGeneratingOutline || isGeneratingContent}
          placeholder={locale.ad_writing_section_title_placeholder}
        />
        <div className="space-x-2 flex items-center">
          <div className="space-x-2 flex items-center">
            {canRefreshSection && (
              <Tooltip content={locale.ad_writing_regenerate_subsection}>
                <Button
                  className="default icon-button"
                  icon={<IconArrowClockwise width="20px" height="20px" />}
                  onClick={() => onRefreshSectionOutline?.(path)}
                  disabled={disabled || isGeneratingOutline || isGeneratingContent || isSubSectionGeneratingContent || isSubSectionGeneratingOutline}
                />
              </Tooltip>
            )}
            {path.length !== 1 && (
              <Button
                type="outline"
                className="generate-button text-xs"
                onClick={() => onRefreshSubSection?.(path)}
                disabled={disabled || isGeneratingOutline || isGeneratingContent}
              >
                {locale.ad_writing_generate}
              </Button>
            )}
            <Button
              type="text"
              className="text-icon-button"
              icon={<IconArrowUp className="text-xl" />}
              disabled={!canMoveUp || disabled || isGeneratingOutline || isGeneratingContent}
              onClick={() => onClickMoveUp?.(path)}
            />
            <Button
              type="text"
              className="text-icon-button"
              icon={<IconArrowDown className="text-xl" />}
              disabled={!canMoveDown || disabled || isGeneratingOutline || isGeneratingContent}
              onClick={() => onClickMoveDown?.(path)}
            />
            <Button
              disabled={disabled || isGeneratingOutline || isGeneratingContent}
              type="text"
              className="text-icon-button"
              icon={<IconTrash width="20px" height="20px" />}
              onClick={() => onClickDelete?.(path)}
            />
          </div>
        </div>
      </div>
      {canAddSummary && (
        <div className="space-x-1">
          <Button
            disabled={(section.sectionContents && section.sectionContents.length > 0) || disabled || isGeneratingSummary}
            type="outline"
            size="small"
            icon={<IconPlus />}
            className="outline-button text-xs"
            onClick={() => onAddSummary?.(path)}
          >
            {locale.ad_writing_add_summary}
          </Button>
          <Tooltip content={locale.ad_writing_remove_summary}>
            <div className="inline">
              <Button
                disabled={!section.sectionContents || section.sectionContents.length === 0 || disabled || isGeneratingSummary}
                icon={<IconMinus />}
                className="icon-button small outline-button"
                onClick={() => onRemoveSummary?.(path)}
              />
            </div>
          </Tooltip>
          <Tooltip content={locale.ad_writing_regenerate_summary}>
            <div className="inline">
              <Button
                disabled={!section.sectionContents || section.sectionContents.length === 0 || disabled || isGeneratingSummary}
                icon={<IconArrowClockwise />}
                className="icon-button small outline-button"
                onClick={() => onRefreshSummary?.(path)}
              />
            </div>
          </Tooltip>
        </div>
      )}
      <div className="pl-4">
        {section.subLevels?.map?.((subSection, index) => (
          <Section
            key={subSection.sectionId}
            section={subSection}
            path={[...path, index]}
            onClickMoveUp={onClickMoveUp}
            onClickMoveDown={onClickMoveDown}
            onClickDelete={onClickDelete}
            canMoveUp={index !== 0}
            canMoveDown={section.subLevels && index !== section.subLevels.length - 1}
            onTitleChange={onTitleChange}
            onRefreshSubSection={onRefreshSubSection}
            disabled={disabled || isGeneratingOutline || isGeneratingContent}
          />
        ))}
      </div>
      <div className="flex justify-between">
        {canAddSubSection && (
          <Button className="default text-xs" icon={<IconPlus />} onClick={() => onAddSubsSection?.(path)} disabled={disabled || isGeneratingOutline}>
            {locale.ad_writing_add_subsection}
          </Button>
        )}
        {path.length === 1 && (
          <Button type="outline" className="generate-button text-xs" onClick={() => onRefreshSection?.(path)} disabled={disabled || isGeneratingOutline || isGeneratingContent}>
            {locale.ad_writing_generate}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Section;
