import { Question, QuestionType, Option, OptionType } from '@hkgai/pb-fe-api/lib/interface/survey_api';
import { FC, useMemo } from 'react';
import { Form, Input } from '@arco-design/web-react';
import RenderQuestionOptions from '../question-options';

interface RenderQuestionProps {
  question: Question;
  questionType?: QuestionType;
  userAnswer?: { [key: string]: string };
}

interface SplitResult {
  questionId?: number;
  questionType?: OptionType;
  score?: number;
}

const RenderQuestion: FC<RenderQuestionProps> = ({ question, questionType = QuestionType.SINGLECHOICE, userAnswer = {} }) => {
  const splitStringToArray = (val: string, separator = '_'): SplitResult => {
    if (!val) {
      return { questionId: undefined, questionType: undefined, score: 0 };
    }
    if (typeof val === 'number') {
      return { questionId: undefined, questionType: undefined, score: val };
    }
    const array = val?.split?.(separator);
    const questionId = Number(array[0]);
    const questionType = Number(array[1]);
    return { questionId, questionType, score: 0 };
  };

  const [hasOtherSelected, rateScore] = useMemo(() => {
    if (!Object.keys(userAnswer).length) {
      return [false, 0];
    }

    const selectedVals = Object.values(userAnswer).flat();

    if (!selectedVals.length) {
      const isOtherSelected = false;
      return [isOtherSelected, 0];
    }

    if (selectedVals.length > 1) {
      const isOtherSelected = selectedVals.some((item: string) => {
        const { questionType } = splitStringToArray(item);
        return questionType === OptionType.SELECTIONWITHINPUTTING;
      });
      return [isOtherSelected, 0];
    }

    const { questionType, score = 0 } = splitStringToArray(selectedVals[0]);
    if (questionType) {
      const isOtherSelected = questionType === OptionType.SELECTIONWITHINPUTTING;
      return [isOtherSelected, 0];
    }
    const isOtherSelected = false;
    return [isOtherSelected, score];
  }, [userAnswer]);

  const getQuestionInfo = (question: Question) => {
    const questionTypeMapping: Record<QuestionType, any> = {
      [QuestionType.SINGLECHOICE]: question.singleChoiceQuestion,
      [QuestionType.MULTICHOICE]: question.multiChoiceQuestion,
      [QuestionType.QUIZ]: question.quizQuestion,
      [QuestionType.RATE]: question.rateQuestion,
    };

    const questionData = questionTypeMapping[questionType || QuestionType.SINGLECHOICE];
    return {
      title: questionData.questionDesc,
      options: questionType === QuestionType.RATE ? questionData : questionData.options || ([] as Option[]),
    };
  };

  const questionOption = useMemo(() => {
    const { title, options } = getQuestionInfo(question);
    const extra = questionType === QuestionType.RATE ? options?.extra : options.find((option: Option) => option.extra)?.extra;
    return {
      title,
      options,
      extra,
    };
  }, [question.questionId, questionType]);

  return (
    <>
      <Form.Item
        field={`${question.questionId}_${question.questionType}.userAnswer`}
        label={questionOption.title}
        className="question-item"
        triggerPropName={questionType === question.questionType ? 'checked' : undefined}
      >
        <RenderQuestionOptions options={questionOption.options} questionType={questionType} value={userAnswer} />
      </Form.Item>
      {((hasOtherSelected && questionType !== QuestionType.RATE) || rateScore > 0) && (
        <Form.Item field={`${question.questionId}_${question.questionType}.inputText`}>
          <Input.TextArea placeholder={questionOption?.extra?.placeholder || 'Please specify'} className="input-textarea resize-none" />
        </Form.Item>
      )}
    </>
  );
};

export default RenderQuestion;
