import { Checkbox, Input, Radio, Rate } from '@arco-design/web-react';
import { Option, QuestionType } from '@hkgai/pb-fe-api/lib/interface/survey_api';
import { FC } from 'react';

interface QuestionProps {
  options: Option[];
  questionType: QuestionType;
  onChange?: (value: any) => void;
  value?: any;
}

const RenderQuestionOptions: FC<QuestionProps> = ({ options = [], questionType, value, onChange }) => {
  const handleChange = (newValue: any) => {
    onChange?.(newValue);
  };

  const renderQuestionInput = () => {
    switch (questionType) {
      case QuestionType.MULTICHOICE:
        return (
          <Checkbox.Group
            onChange={v => {
              handleChange({ ...value, checkboxVal: v });
            }}
            direction="vertical"
            value={value?.checkboxVal}
          >
            {options.map((opt: Option) => (
              <Checkbox key={opt.optionId} value={`${opt.orderIndex}_${opt.optionType}`}>
                {opt.optionDesc}
              </Checkbox>
            ))}
          </Checkbox.Group>
        );
      case QuestionType.QUIZ:
        return (
          <Input.TextArea
            placeholder="Please specify"
            autoSize
            onChange={v => {
              handleChange({ ...value, inputVal: v });
            }}
            value={value?.inputVal}
          />
        );
      case QuestionType.RATE:
        return (
          <Rate
            onChange={v => {
              handleChange({ ...value, rateVal: v });
            }}
            value={value?.rateVal}
          />
        );
      default:
        return (
          <Radio.Group
            direction="vertical"
            onChange={v => {
              handleChange({ ...value, radioVal: v });
            }}
            value={value?.radioVal}
          >
            {options?.map((opt: any) => (
              <Radio key={opt.orderIndex} value={`${opt.orderIndex}_${opt.optionType}`}>
                {opt.optionDesc}
              </Radio>
            ))}
          </Radio.Group>
        );
    }
  };
  return <>{renderQuestionInput()}</>;
};

export default RenderQuestionOptions;
