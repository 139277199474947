import i18nLanguage from '@hkgai/i18n-lang-packs/lib/ai_writing_optimization_language.json';
// import { writeI18n } from './write';
// import { pwdI18n } from './pwd';
// import { loginI18n } from './login';
// import { userDropdownI18n } from './user-dropdown';
// import { exitModalI18n } from './exit-modal';
// import { sidebarI18n } from './sidebar';
// import { feedbackI18n } from './feedback';
// import { shuffleCaseI18n } from './shuffle-case';
// import { proofreadingI18n } from './proofreading';
// import { aiBotI18n } from './ai-bot';
// import { uploadModalI18n } from './upload-modal';
// import { writePromptsI18n } from './write-prompt';
// import { promptHistoryI18n } from './prompt-history';
import { toolsbarI18n } from './toolsbar';
// import { adWritingI18n } from './advanced_writing';

const i18n: { [key: string]: any } = {
  'en-US': {
    // 'inputBox.settings.references.text': 'References',
    // 'inputBox.settings.references.tooltip': 'Set References',
    // 'inputBox.settings.style.text': 'Style',
    // 'inputBox.settings.style.tooltip': 'Text Style',
    // 'inputBox.settings.language.tooltip': 'Text Language',
    // 'inputBox.send.button.tooltip': 'Send',
    // 'reminder.info':
    //   'The HK Copilot will not store any user submitted conversation history and reference data. All of your submitted data will not be used for training of any HKGAI solution and model. Nevertheless, the HK Copilot is hosted on public cloud. Please do not submit any classified or sensitive or personal data.',

    // h5_page_alert_info: 'Please open this page in your PC web browser for a smooth browsing experience.',
    // layout_head_notification_dropdown: 'No Reply',
    // layout_head_actions_guidelines: 'Usage tutorial',
    // layout_head_actions_change_log: 'Change Log',
    // layout_head_actions_notification: 'Notification',

    // ...writeI18n['en-US'],
    // ...pwdI18n['en-US'],
    // ...loginI18n['en-US'],
    // ...userDropdownI18n['en-US'],
    // ...exitModalI18n['en-US'],
    // ...sidebarI18n['en-US'],
    // ...feedbackI18n['en-US'],
    // ...shuffleCaseI18n['en-US'],
    // ...proofreadingI18n['en-US'],
    // ...aiBotI18n['en-US'],
    // ...uploadModalI18n['en-US'],
    // ...writePromptsI18n['en-US'],
    // ...promptHistoryI18n['en-US'],
    ...toolsbarI18n['en-US'],
    ...i18nLanguage['en-US'],
    // ...adWritingI18n['en-US'],
  },
  'zh-CN': {
    // 'inputBox.settings.references.text': '参考引用',
    // 'inputBox.settings.references.tooltip': '设置参考引用',
    // 'inputBox.settings.style.text': '风格',
    // 'inputBox.settings.style.tooltip': '文本风格',
    // 'inputBox.settings.wordNum.tooltip': '文本长度',
    // 'inputBox.settings.language.tooltip': '文本语言',
    // 'inputBox.send.button.tooltip': '发送',
    // 'reminder.info':
    //   '重要提示: HK Copilot 不会储存任何使用者提交的对答记录和参考资料。您提交的所有资料均不会用于任何 HKGAI 方案或模型的训练。然而，HK Copilot 在公有云上运行。请不要提交任何机密、敏感或个人资料。',

    // h5_page_alert_info: '请在您的PC端Web浏览器中打开此页面,享受流畅的浏览体验。',
    // layout_head_notification_dropdown: '暂无通知',
    // layout_head_actions_guidelines: '使用指引',
    // layout_head_actions_change_log: '更新日志',
    // layout_head_actions_notification: '通知',

    // ...writeI18n['zh-CN'],
    // ...pwdI18n['zh-CN'],
    // ...loginI18n['zh-CN'],
    // ...userDropdownI18n['zh-CN'],
    // ...exitModalI18n['zh-CN'],
    // ...sidebarI18n['zh-CN'],
    // ...feedbackI18n['zh-CN'],
    // ...shuffleCaseI18n['zh-CN'],
    // ...proofreadingI18n['zh-CN'],
    // ...aiBotI18n['zh-CN'],
    // ...uploadModalI18n['zh-CN'],
    // ...writePromptsI18n['zh-CN'],
    // ...promptHistoryI18n['zh-CN'],
    ...toolsbarI18n['zh-CN'],
    ...i18nLanguage['zh-CN'],
    // ...adWritingI18n['zh-CN'],
  },
  'zh-HK': {
    // 'inputBox.settings.references.text': '參考引用',
    // 'inputBox.settings.references.tooltip': '設置參考引用',
    // 'inputBox.settings.style.text': '風格',
    // 'inputBox.settings.style.tooltip': '文本風格',
    // 'inputBox.settings.wordNum.tooltip': '文本長度',
    // 'inputBox.settings.language.tooltip': '文本語言',
    // 'inputBox.send.button.tooltip': '發送',
    // 'reminder.info':
    //   '重要提示: HK Copilot 不會儲存任何使用者提交的對答記錄和參考資料。您提交的所有資料均不會用於任何 HKGAI 方案或模型的訓練。然而，HK Copilot 在公有雲上運行。請不要提交任何機密、敏感或個人資料。',

    // h5_page_alert_info: '請在您的PC端Web瀏覽器中打開此頁面，享受流暢的瀏覽體驗。',
    // layout_head_notification_dropdown: '暫無通知',
    // layout_head_actions_guidelines: '使用指引',
    // layout_head_actions_change_log: '更新日誌',
    // layout_head_actions_notification: '通知',

    // ...writeI18n['zh-HK'],
    // ...pwdI18n['zh-HK'],
    // ...loginI18n['zh-HK'],
    // ...userDropdownI18n['zh-HK'],
    // ...exitModalI18n['zh-HK'],
    // ...sidebarI18n['zh-HK'],
    // ...feedbackI18n['zh-HK'],
    // ...shuffleCaseI18n['zh-HK'],
    // ...proofreadingI18n['zh-HK'],
    // ...aiBotI18n['zh-HK'],
    // ...uploadModalI18n['zh-HK'],
    // ...writePromptsI18n['zh-HK'],
    // ...promptHistoryI18n['zh-HK'],
    ...toolsbarI18n['zh-HK'],
    ...i18nLanguage['zh-HK'],

    // ...adWritingI18n['zh-HK'],
  },
};

export default i18n;
