import './index.scss';

import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useLocale from '@hooks/useLocale';
import { Button, Message, Tooltip } from '@arco-design/web-react';
import { BaseRange, Editor, ReactEditor, Transforms } from '@hkgai/slate-plugin/dist/lib/delta';
import { useModel } from '@modern-js/runtime/model';
import { IconCheck, IconInfo } from '@arco-iconbox/react-hkgai-fe-icon';
import { IconWarningCircleBlue, IconCopy, IconArrowClockwise, IconTrash } from '@arco-iconbox/react-hkgai-government-icon';
import { IconLeft } from '@arco-design/web-react/icon';
import { RefTextAreaType } from '@arco-design/web-react/es/Input';
import classNames from 'classnames';
import { copyTextUsingClipboardAPI, countWords } from '../../util';
import FeedbackRate from '../feedback-rate';
import InputTextArea from '../input-textarea';
import { writingTypeLocaleKeyMap } from '../../constant';
import Generating from '../generating';
import { WritingType, AIWorkStatus, WritingTypeText } from '@/type/ui';
import { useWritingContext } from '@/context/writingContext';
import EditorStore from '@/store/editorStore';
import { getGenerateParam } from '@/utils/generateParam';
import { chatResultReport, needScoreFeedback } from '@/api/copilot_api';
import { ModuleName, PageNameConfig, WritingPageEvent } from '@/config/track.config';
import { GlobalContext } from '@/routes/context';
import { MAX_USER_PROMPT_LEN } from '@/config/constant';
import PromptHistoryStore from '@/store/promptHistoryStore';
import { InstructionFeedbackType } from '@/type/copilot';

interface GenerationResultProps {
  reGenerate?: (newSelection?: BaseRange) => void;
  onCancel?: () => void;
}

enum ButtonType {
  DEFAULT,
  USE,
  RETRY,
  DELETE,
}

const GenerationResult: FC<GenerationResultProps> = ({ reGenerate, onCancel }) => {
  const locale = useLocale();
  const textareaRef = useRef<RefTextAreaType>(null);

  const { editor, insertContent } = useWritingContext();
  const { collectEvent } = useContext(GlobalContext);

  const [
    { workStatus, writingType, generatingText, editorSelection, writeEssayParam, isBuiltInPrompt },
    { changeWorkStatus, setWritingType, setIsAIWork, resetGeneratingText, changeEditorSelection, setWriteEssayParam },
  ] = useModel(EditorStore);
  const [, { addAIWritingPromptHistory, addtoolbarPromptHistory }] = useModel(PromptHistoryStore);
  const [promptInputReadonly, setPromptReadonly] = useState(true);
  const [feedBackModalVisiable, setFeedBackModalVisiable] = useState(false);

  const [buttonType, setButtonType] = useState(ButtonType.DEFAULT);
  const [alreadyShow, setAlreadyShow] = useState(false);

  useEffect(() => {
    const resultContainer = document.getElementById('generation-result');
    if (generatingText !== '' && resultContainer) {
      resultContainer.scrollTop = resultContainer.scrollHeight;
    }
  }, [generatingText]);

  const generateLangLabel = useMemo(() => {
    const languageOpts = [
      { label: 'ai.writing.translate.english', value: 'English' },
      { label: 'ai.writing.translate.simplified', value: '简体' },
      { label: 'ai.writing.translate.traditional', value: '繁體' },
    ];
    return locale[languageOpts.find(e => e.value === writeEssayParam.lang)?.label || ''] || '';
  }, [writeEssayParam.lang, locale]);

  const generationResultHint = useMemo(
    () => (
      <div className="generation-result-hint">
        <div className="flex gap-20 justify-between">
          <div className="flex items-center gap-4">
            <div className="prompt-message">
              <span className="whitespace-nowrap">{locale['ai.writing.generate.count']}:</span>
              <span className="ml-1" style={{ color: '#666' }}>
                {countWords(generatingText)}
              </span>
            </div>
            <div className="prompt-message">
              <span>{locale['inputBox.settings.language.text']}:</span>
              <span className="ml-1" style={{ color: '#666' }}>
                {generateLangLabel}
              </span>
            </div>
          </div>
          <Tooltip content={locale['ai.writing.generate.warning']}>
            <div className="flex items-center overflow-hidden">
              <IconInfo style={{ fontSize: '12px', verticalAlign: '-3', flex: 'none' }} />
              <span className="whitespace-nowrap overflow-hidden text-ellipsis">{locale['ai.writing.generate.warning']}</span>
            </div>
          </Tooltip>
        </div>
      </div>
    ),
    [locale, generatingText, generateLangLabel],
  );

  /// 提交反馈弹窗
  const feedBackModal = async (useType?: ButtonType) => {
    setAlreadyShow(true);
    try {
      const response = await needScoreFeedback({});
      if (response.isNeedShow) {
        setFeedBackModalVisiable(true);
      } else {
        scoredAfterEvent(useType);
      }
    } catch (e) {
      scoredAfterEvent(useType);
    }
  };

  /// 提交反馈后的操作
  const scoredAfterEvent = async (useType?: ButtonType) => {
    console.log('scoredAfterEvent');
    if (useType === ButtonType.USE || buttonType === ButtonType.USE) {
      applyResult();
    }

    if (useType === ButtonType.RETRY || buttonType === ButtonType.RETRY) {
      reGenerateContent();
    }

    if (useType === ButtonType.DELETE || buttonType === ButtonType.DELETE) {
      deleteApply();
    }
    setButtonType(ButtonType.DEFAULT);
  };

  /// 反馈
  const feedBackConfirm = async (formValues: any) => {
    try {
      if (!formValues) {
        return;
      }
      let one = 0;
      if (formValues?.one) {
        one = parseInt(formValues?.one, 10);
      }
      let two = 0;
      if (formValues?.two) {
        two = parseInt(formValues?.two, 10);
      }
      let three = 0;
      if (formValues?.three) {
        three = parseInt(formValues?.three, 10);
      }

      let params = {
        feedback_type: InstructionFeedbackType.IFT_TEXT,
        scores: [one, two, three],
      };
      const hasComment = formValues?.remark?.length > 0;
      const hasScore = one || two || three;
      if (hasScore) {
        params.feedback_type = InstructionFeedbackType.IFT_SCORE;
      }
      if (hasComment) {
        params = { ...getGenerateParam(), ...params, comments: [formValues?.remark] };
      }
      if (hasComment && hasScore) {
        params.feedback_type = InstructionFeedbackType.IFT_SCORE_TEXT;
      }
      await chatResultReport(params);
      setFeedBackModalVisiable(false);
      scoredAfterEvent();
      Message.success(locale['ai.writing.generate.feedback.success']);
    } catch (error: any) {
      Message.error(locale['ai.writing.generate.feedback.fail']);
    }
  };

  const deleteApply = async () => {
    changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
    // 定位光标到最初选择的位置
    ReactEditor.focus(editor!);
    const [startPoint, endPoint] = Editor.edges(editor!, editorSelection!);

    if (writingType === WritingType.AIWriting || writingType === WritingType.CONTINUATION) {
      Transforms.delete(editor!, { at: editorSelection });
      Transforms.select(editor!, startPoint);
      changeEditorSelection({ anchor: startPoint, focus: startPoint });
    }

    if (writingType === WritingType.EXPANSION || writingType === WritingType.REWRITE || writingType === WritingType.SUMMARIZE || writingType === WritingType.TRANSLATE) {
      Transforms.select(editor!, endPoint);
      changeEditorSelection({ anchor: endPoint, focus: endPoint });
    }
    setIsAIWork?.(false);
    setWritingType(WritingType.UNKNOWN);
    resetGeneratingText();
  };

  const applyResult = () => {
    console.log('applyResult');
    changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
    if ((writingType === WritingType.AIWriting || writingType === WritingType.CONTINUATION) && editor) {
      if (editorSelection) {
        ReactEditor.focus(editor);
        Transforms.select(editor, editorSelection);
      }
    } else {
      const currentSelection = editorSelection;
      if (currentSelection && editor) {
        const endPoint = editor.end(currentSelection);
        const insertPointRef = Editor.pointRef(editor, endPoint);
        // 删除之前选中的内容
        if (writingType !== WritingType.TRANSLATE) {
          Transforms.insertText(editor, '', {
            at: currentSelection,
          });
        }

        const insertedRange = insertContent!(`${generatingText}`, insertPointRef.unref()!, true);

        // 选中生成的内容
        ReactEditor.focus(editor);
        Transforms.select(editor, insertedRange);
        changeEditorSelection(insertedRange);
      }
    }
    collectEvent?.([
      {
        event: WritingPageEvent.CLICK_AI_WRITING,
        params: {
          pageName: PageNameConfig.WRITING_PAGE,
          moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
          action: WritingTypeText[writingType || WritingType.UNKNOWN],
        },
      },
    ]);

    setIsAIWork?.(false);
    setWritingType(WritingType.UNKNOWN);
  };

  const onCopyText = (e: any) => {
    e.stopPropagation();
    copyTextUsingClipboardAPI(generatingText, () => Message.success(locale['copy.success.toast']));
    collectEvent?.([
      {
        event: WritingPageEvent.CLICK_COPY,
        params: {
          pageName: PageNameConfig.WRITING_PAGE,
          moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
          action: WritingTypeText[writingType || WritingType.UNKNOWN],
        },
      },
    ]);
  };

  const reGenerateContent = () => {
    setPromptReadonly(true);
    let newSelection = editorSelection;
    if (writingType === WritingType.AIWriting || writingType === WritingType.CONTINUATION) {
      const [startPoint, endPoint] = Editor.edges(editor!, editorSelection!);
      // 删除内容
      Transforms.delete(editor!, { at: editorSelection });
      // 重置选中区域
      newSelection = {
        anchor: startPoint,
        focus: startPoint,
      };
      changeEditorSelection(newSelection);
    }

    if (newSelection) {
      ReactEditor.focus(editor!);
      Transforms.select(editor!, newSelection);
    }
    // changeWorkStatus?.(AIWorkStatus.WORK_PENDING);
    // setIsAIWork?.(false);

    resetGeneratingText();
    // 新的selection临时这样处理一下，不然函数作用域有闭包，拿不到model里最新的editorSelection
    reGenerate?.(newSelection);
    if (writingType === WritingType.AIWriting) {
      addAIWritingPromptHistory(writeEssayParam.content);
    } else {
      addtoolbarPromptHistory({
        writingType,
        prompt: writeEssayParam.content,
      });
    }
  };

  useEffect(() => {
    if (!promptInputReadonly) {
      textareaRef.current?.focus();
      const rangeEnd = textareaRef.current?.dom.value.length || 0;
      textareaRef.current?.dom.setSelectionRange(rangeEnd, rangeEnd);
    }
  }, [promptInputReadonly]);

  const writingTypeHint = useMemo(() => {
    const writingTypeLocale = writingType ? locale[writingTypeLocaleKeyMap[writingType] || ''] : '';
    if (writingTypeLocale) {
      return <div className="bg-[#F2F3F5] rounded px-2 py-1 whitespace-nowrap">{writingTypeLocale}</div>;
    }
    return null;
  }, [locale, writingType, writingTypeLocaleKeyMap]);

  return (
    <div className="generation-result-wrapper ">
      <div className="generation-toolbar">
        {writingType !== WritingType.AIWriting && writingType !== WritingType.CONTINUATION && (
          <div className={classNames('generation-result', { complete: workStatus === AIWorkStatus.COMPLETED })}>
            <div className="generation-result-content" id="generation-result">
              {generatingText}
              {/* <TextLoading show={workStatus === AIWorkStatus.WORKING} /> */}
            </div>
          </div>
        )}
        <div className="flex items-center justify-between gap-3">
          <div className="flex-1 overflow-hidden">{generationResultHint}</div>
        </div>
        {workStatus === AIWorkStatus.COMPLETED && (
          <div className="menu">
            <div className="w-full flex gap-2 items-center overflow-hidden">
              {promptInputReadonly ? (
                <>
                  {/* click apply  */}
                  <div
                    className="btn-done"
                    onClick={e => {
                      e.stopPropagation();
                      if (alreadyShow) {
                        applyResult();
                      } else {
                        setButtonType(ButtonType.USE);
                        feedBackModal(ButtonType.USE);
                      }
                    }}
                  >
                    <IconCheck />
                    {locale['ai.writing.generate.button.use']}
                  </div>
                  {/* click copy */}
                  <div className="btn-toolbar" onClick={onCopyText}>
                    <IconCopy />
                  </div>
                  <div
                    className="btn-toolbar"
                    onClick={e => {
                      e.stopPropagation();
                      if (alreadyShow) {
                        reGenerateContent();
                      } else {
                        setButtonType(ButtonType.RETRY);
                        feedBackModal(ButtonType.RETRY);
                      }
                    }}
                  >
                    <IconArrowClockwise />
                  </div>
                  {/* click delete */}
                  <div
                    className="btn-toolbar"
                    onClick={e => {
                      e.stopPropagation();
                      if (alreadyShow) {
                        deleteApply();
                      } else {
                        setButtonType(ButtonType.DELETE);
                        feedBackModal(ButtonType.DELETE);
                      }
                    }}
                  >
                    <IconTrash />
                  </div>
                  <div className="flex items-center ml-6 flex-1 overflow-hidden">
                    <span className="whitespace-nowrap mr-2">{locale['ai.writing.generate.prompt']}</span>
                    <div
                      className="h-9 flex items-center cursor-pointer border-[#E5E5E5] border-solid px-2 pl-1 rounded-lg overflow-hidden flex-1 mr-2"
                      onClick={e => {
                        setPromptReadonly(false);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {writingTypeHint}
                      <div className="text-ellipsis overflow-hidden whitespace-nowrap w-full ml-1">{writeEssayParam.content}</div>
                    </div>
                    <div
                      className="btn_report cursor-pointer"
                      onClick={() => {
                        feedBackModal();
                      }}
                    >
                      <IconWarningCircleBlue />
                      {locale['ai.writing.generate.button.report']}
                    </div>
                  </div>
                </>
              ) : (
                <div className="flex w-full">
                  <IconLeft
                    className="cursor-pointer w-5 h-5 mr-2 mt-[6px] hover:bg-gray-100"
                    onClick={() => {
                      setPromptReadonly(true);
                      const textareaDom = textareaRef.current?.dom;
                      textareaDom?.setSelectionRange?.(0, 0);
                    }}
                  />
                  <InputTextArea
                    autoSize
                    ref={textareaRef}
                    readOnly={promptInputReadonly}
                    value={writeEssayParam.content}
                    onChange={val => {
                      let value = val;
                      if (val.length >= MAX_USER_PROMPT_LEN) {
                        value = val.slice(0, MAX_USER_PROMPT_LEN);
                      }
                      setWriteEssayParam({ content: value });
                    }}
                    onPressEnter={e => {
                      e.stopPropagation();
                      if (e.shiftKey) {
                        return;
                      }
                      reGenerateContent();
                    }}
                    className={`rounded leading-6 min-h-9 ${promptInputReadonly ? 'max-h-8' : 'max-h-80'}`}
                  />
                  <Button
                    type="primary"
                    className="rounded ml-2 h-9"
                    onClick={e => {
                      e.stopPropagation();
                      reGenerateContent();
                    }}
                  >
                    {locale['ai.writing.generate.tooltip.restart']}
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {(workStatus === AIWorkStatus.WORK_PENDING || workStatus === AIWorkStatus.WORKING) && <Generating cancel={onCancel} />}
      </div>

      <FeedbackRate
        visiable={feedBackModalVisiable}
        onCancel={() => {
          setFeedBackModalVisiable(false);
          scoredAfterEvent();
        }}
        onOk={formValues => {
          feedBackConfirm(formValues);
        }}
      />
    </div>
  );
};

export default GenerationResult;
