import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useMemoizedFn } from 'ahooks';
import {
  BOLD_KEY,
  DOWNLOAD_DOCX_KEY,
  DOWNLOAD_PDF_KEY,
  FONT_SETTING_KEY,
  ITALIC_KEY,
  STRIKE_THROUGH_KEY,
  STYLE_SETTING_KEY,
  UNDERLINE_KEY,
} from '@hkgai/slate-plugin/dist/lib/plugins';
import { Editor, ReactEditor, TextElement } from '@hkgai/slate-plugin/dist/lib/delta';
import { getBlockNode } from '@hkgai/slate-plugin/dist/lib/utils';
import { useModel } from '@modern-js/runtime/model';
import { SidebarType } from '@hkgai/pb-fe-api/lib/interface/copilot_api';
import { clone, debounce } from 'lodash-es';
import { EDITOR_EVENT } from '@hkgai/slate-plugin/dist/lib/core';
import TriggrtMenu from './components/triggerMenu';
import DefaultMenu from './components/defaultMenu';
import styles from './index.module.scss';
import FontMenu from './components/fontMenu';
import GroupMenu from './components/groupMenu';
import ColorMenu from './components/colorMenu';
import { menu } from './config';
import { BLOCK_LIST, CustomEventOptions, DoActions, SelectedMarks, SelectedMarksKeys, TOOL_MENU_GROUP_ACTIONS, TOOL_MENU_GROUP_DOS } from '@/type/toolsbar';
import { useWritingContext } from '@/context/writingContext';
import EditorStore from '@/store/editorStore';
import GlobalStore from '@/store/globalStore';
// import useLocale from '@/hooks/useLocale';

const TEXT_KEYS = [BOLD_KEY, STRIKE_THROUGH_KEY, ITALIC_KEY, UNDERLINE_KEY, STYLE_SETTING_KEY, FONT_SETTING_KEY];

const ToolBar: FC<{
  clear?: () => void;
  download?: () => void;
  rightFloatDom?: ReactNode;
  articleTitle?: string;
  articleId?: string;
}> = ({ clear, download, rightFloatDom, articleTitle, articleId }) => {
  //   const isHovering = useHover(ref);
  // const { setLang, lang } = useContext(GlobalContext);
  // const locale = useLocale();
  const { editorKit, editor } = useWritingContext();
  const [{ editorContent }] = useModel(EditorStore);
  const [{ sidebarType = SidebarType.UNKNOWN }] = useModel(GlobalStore);
  const [selectedMarks, setSelectedMarks] = useState<SelectedMarks>({});
  // 执行方法
  const exec = useMemoizedFn((param: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // 找到第一个点的索引
    const dotIndex = param.indexOf('.');
    // 获取点前面的字符
    const key = dotIndex === -1 ? param : param.substring(0, dotIndex);
    // 获取点后面的字符
    const extraKey = dotIndex === -1 ? undefined : param.substring(dotIndex + 1);

    if (!editor) {
      return;
    }

    const { selection } = editor;

    // 撤销
    if (key === DoActions.UNDO) {
      editorKit!.raw.undo();
      return;
    }
    // 还原
    if (key === DoActions.REDO) {
      editorKit!.raw.redo();
      return;
    }
    // 删除
    if (key === CustomEventOptions.DELETE && editorContent?.plain) {
      clear?.();
      return;
    }
    // 下载PDF/DOCX
    if (key === DOWNLOAD_DOCX_KEY || key === DOWNLOAD_PDF_KEY) {
      editorKit!.command.exec(key, { extraKey: articleTitle, articleId, origin: key });
      return;
    }
    if (!selection || !editorContent?.plain) {
      return;
    }
    const { focus, anchor } = selection;
    // 位置信息
    const element = getBlockNode(editor, { at: selection.focus.path })?.block;
    const path = ReactEditor.findPath(editor, element!);

    const marks = Editor.marks(editor);
    const position = { left: 0, top: 0 };

    // inline插件 并且有选区
    if (TEXT_KEYS.includes(key) && focus !== anchor) {
      editorKit!.command.exec(key, {
        extraKey,
        event,
        position,
        marks: marks as TextElement,
      });
      return;
    }
    editorKit!.command.exec(key, { extraKey, element, path, origin: key });
  });

  const handlerSelectionChange = debounce(() => {
    if (editor?.selection) {
      const obj: any = {};
      const path = clone(editor.selection.focus.path);
      while (path.length) {
        const tuple = Editor.node(editor, path);
        if (!tuple) {
          return;
        }
        const [node] = tuple;
        const keysObj = Object.entries(node);
        keysObj.forEach(([key, value]) => {
          if (BLOCK_LIST.includes(key)) {
            obj[key as SelectedMarksKeys] = value;
          }
        });
        path.pop();
      }
      setSelectedMarks(obj);
    }
  }, 100);

  useEffect(() => {
    document.addEventListener('selectionchange', handlerSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handlerSelectionChange);
    };
  }, [editor]);

  useEffect(() => {
    editorKit?.event.on(EDITOR_EVENT.CONTENT_CHANGE, handlerSelectionChange);
    return () => {
      editorKit?.event.off(EDITOR_EVENT.CONTENT_CHANGE, handlerSelectionChange);
    };
  }, [editorKit?.event]);

  const toolbar = useMemo(() => {
    return (
      <>
        {menu.map(item => {
          const { icon, key, options = {} } = item;
          const { commonConfig, colorMap, fontSizeMap } = options;
          if (key === TOOL_MENU_GROUP_DOS || key === TOOL_MENU_GROUP_ACTIONS) {
            return (
              <GroupMenu
                disabled={sidebarType !== SidebarType.UNKNOWN}
                key={key}
                options={options}
                onSelect={exec}
                editorContentPlain={editorContent?.plain}
                editorKit={editorKit}
              />
            );
          }
          if (commonConfig) {
            return (
              <TriggrtMenu
                disabled={sidebarType !== SidebarType.UNKNOWN}
                key={key}
                menuKey={key}
                options={options}
                onSelect={exec}
                selectedMarks={selectedMarks}
                menuWidth={item.menuWidth}
                checkIcon={key !== CustomEventOptions.DOWNLOAD}
              >
                {icon}
              </TriggrtMenu>
            );
          }
          if (colorMap) {
            return (
              <ColorMenu disabled={sidebarType !== SidebarType.UNKNOWN} key={key} menuKey={key} options={options} onSelect={exec}>
                {icon}
              </ColorMenu>
            );
          }
          if (fontSizeMap) {
            return <FontMenu disabled={sidebarType !== SidebarType.UNKNOWN} key={key} fontSizeMap={fontSizeMap} onSelect={exec} menuKey={key} selectedMarks={selectedMarks} />;
          }
          return (
            <DefaultMenu disabled={sidebarType !== SidebarType.UNKNOWN} key={key} menuKey={key} onSelect={exec} selectedMarks={selectedMarks}>
              {icon}
            </DefaultMenu>
          );
        })}
      </>
    );
  }, [menu, exec, editorContent?.plain, selectedMarks, sidebarType]);

  return (
    <div className={styles.containerBox} onMouseDown={e => e.preventDefault()}>
      <div className={styles.container}>{toolbar}</div>
      <div className={styles.rightFloatDom}>{rightFloatDom}</div>
    </div>
  );
};

export default ToolBar;
