import { FC, useMemo } from 'react';
import { Button, Input, Spin } from '@arco-design/web-react';
import './index.scss';
import { useModel } from '@modern-js/runtime/model';
import AttachmentToolbar from '@/components/attachment-toolbar';
import Attachment from '@/components/attachment';
import AdvancedWritingStore from '@/store/advancedWritingStore';
import { getReferencesContent } from '@/views/writing/util';
import useLocale from '@/hooks/useLocale';
import { useArticleId } from '@/hooks/article';
import { MAX_USER_REFERENCE_LEN } from '@/config/constant';

const Step2: FC<{
  onNextStep: () => void;
  onPreviousStep: () => void;
}> = ({ onNextStep, onPreviousStep }) => {
  const locale = useLocale();

  const [{ writingParams, sections }, { setWritingParams, generateAdvancedWritingOutline }] = useModel(AdvancedWritingStore);

  const articleId = useArticleId();

  const [files, links, texts] = useMemo(() => {
    const _files = [];
    const _links = [];
    const _texts = [];
    for (const item of writingParams.reference) {
      if (item.type === 'file') {
        _files.push(item);
      }
      if (item.type === 'link') {
        _links.push(item);
      }
      if (item.type === 'text') {
        _texts.push(item);
      }
    }
    return [_files, _links, _texts];
  }, [writingParams.reference]);

  return (
    <div className="h-full pb-9">
      <div className="space-y-3 h-full flex flex-col">
        <div>
          <AttachmentToolbar
            onChange={async (updatedFiles, updatedLinkes) => {
              const fileReferences = updatedFiles.map(file => ({ name: file.name || '', type: 'file', content: file.value || '' }));
              let linkReferences = updatedLinkes.map(link => ({ name: link.name || '', type: 'link', content: link.value || '', loading: true }));
              setWritingParams({ reference: [...texts, ...linkReferences, ...fileReferences] });

              // 获取链接的内容
              linkReferences = await Promise.all(
                updatedLinkes.map(link => {
                  return getReferencesContent([link.value || ''])
                    .catch(() => '')
                    .then(content => ({
                      name: link.name || '',
                      type: 'link',
                      content,
                      loading: false,
                    }));
                }),
              );
              setWritingParams({ reference: [...texts, ...fileReferences, ...linkReferences] });
            }}
          />
        </div>
        <div className="flex gap-[10px] flex-wrap max-w-full overflow-hidden text-ellipsis">
          {files.map(item => (
            <Attachment
              key={item.name}
              type="file"
              title={item.name}
              closeable
              onClose={() => {
                setWritingParams({ reference: writingParams.reference.filter(e => e.name !== item.name) });
              }}
            />
          ))}
          {links.map(item => (
            <Spin loading={item.loading} key={item.name} className="w-full">
              <Attachment
                key={item.name}
                type="link"
                title={item.name}
                closeable
                onClose={() => {
                  setWritingParams({ reference: writingParams.reference.filter(e => e.name !== item.name) });
                }}
              />
            </Spin>
          ))}
        </div>
        <Input.TextArea
          className="flex-1"
          value={texts[0]?.content || ''}
          onChange={value => {
            if (value.length > MAX_USER_REFERENCE_LEN) {
              return;
            }
            setWritingParams({
              reference: [
                ...links,
                ...files,
                {
                  name: 'text',
                  type: 'text',
                  content: value,
                },
              ],
            });
          }}
          maxLength={MAX_USER_REFERENCE_LEN}
          placeholder={locale.ad_writing_reference_placeholder}
        />
      </div>

      <div className="generate-outline-form-footer flex justify-between">
        <Button
          onClick={() => {
            onPreviousStep();
          }}
        >
          {locale.ad_writing_back}
        </Button>
        <Button
          type="primary"
          onClick={async () => {
            // 已有大纲不需要重新生成??(对测试case的时候说的哦！)
            if (!sections.length) {
              generateAdvancedWritingOutline({
                articleId,
                ...writingParams,
                genre: writingParams.genre,
              });
            }
            onNextStep();
          }}
        >
          {locale.ad_writing_next}
        </Button>
      </div>
    </div>
  );
};

export default Step2;
