import { EditorRaw } from '@hkgai/slate-plugin/dist/lib/core';
import { Editor, Location, Node, Point, Range, ReactEditor, Transforms } from '@hkgai/slate-plugin/dist/lib/delta';
import { handleMarkdown } from '@hkgai/slate-plugin/dist/lib/utils';
import { calculateToolbarTop } from '../../util';

let _id = 1;
export const generateId = () => {
  return _id++;
};

/**
 * 向富文本编辑器中指定位置插入文本，返回插入文本的range
 * @param editor 富文本编辑器对象
 * @param texts 要插入的文本
 * @param at 插入位置
 * @returns {Range} 插入的文本的Range
 */
export const insertPlainText = (editor: EditorRaw, text: string, at: Location, renderNow = false): Range => {
  const [insertRangeStart, insertRangeEnd] = Editor.edges(editor, at);
  const insertCursorRef = Editor.pointRef(editor, insertRangeEnd);

  let workingEnd: Point = insertRangeEnd;

  if (!text && renderNow) {
    const insertPoint = insertCursorRef.current!;
    handleMarkdown(editor, insertPoint.path);
    workingEnd = Editor.end(editor, insertPoint.path);
    return { anchor: insertRangeStart, focus: workingEnd };
  }

  const textArray = text.split('');
  textArray.forEach((fragment, index) => {
    const insertPoint = insertCursorRef.current!;
    // 将换行符替换为Block Node
    if (fragment === '\n') {
      Transforms.splitNodes(editor, { at: insertPoint, always: true });
      // const currentInsertedRange = insertSlateNodes(editor, [{ children: [{ text: '' }] }], insertPoint);
      // insertCursorRef.unref();
      // insertCursorRef = Editor.pointRef(editor, Editor.end(editor, currentInsertedRange));
      handleMarkdown(editor, insertPoint.path);
    } else {
      Transforms.insertText(editor, fragment, { at: insertPoint });
      if (renderNow && index === textArray.length - 1) {
        handleMarkdown(editor, insertPoint.path);
        workingEnd = Editor.end(editor, insertPoint.path);
      }
    }
    updateToolbarPosition(editor, insertPoint);
  });

  return { anchor: insertRangeStart, focus: insertCursorRef.unref()! ?? workingEnd };
};

/**
 * 向富文本编辑器中指定位置插入slate nodes，返回插入文本的range
 * @param editor 富文本编辑器对象
 * @param nodes 要插入的slate nodes
 * @param at 插入位置
 * @returns {Range} 插入内容后，内容在编辑器的Range
 */
export const insertSlateNodes = (editor: EditorRaw, nodes: Node[], at: Location): Range => {
  const [insertRangeStart, insertRangeEnd] = Editor.edges(editor, at);
  let insertCursorRef = Editor.pointRef(editor, insertRangeEnd);
  for (const node of nodes) {
    const insertCursor = insertCursorRef.current || undefined;
    // 给node增加一个id标记
    const insertNewLineId = generateId();

    Transforms.insertNodes(editor, { ...node, id: insertNewLineId }, { at: insertCursor });
    // 插入Element的情况下PathRef PointRef RangeRef 都不会更新已经插入的Node对位置产生的改变
    // 已知这里已经成功插入一个Element, 所以想尽办法找到刚插入的Element的Path, 获取到最新的插入位置
    // @ts-expect-error
    const nodeEntry = Editor.next(editor, { match: n => n.id === insertNewLineId, at: insertCursor });

    if (nodeEntry) {
      const [, insertedNodePath] = nodeEntry;
      const updatedInsertCursor = Editor.end(editor, insertedNodePath);
      insertCursorRef.unref();
      insertCursorRef = Editor.pointRef(editor, updatedInsertCursor);
      updateToolbarPosition(editor, insertCursor!);
    }
  }

  // return and clean up
  const insertedRange: Range = { anchor: insertRangeStart, focus: insertCursorRef.unref()! };
  return insertedRange;
};

export const updateToolbarPosition = (editor: EditorRaw, location: Location) => {
  const timer = setTimeout(() => {
    try {
      const insertEndPoint = Editor.end(editor, location);
      const [insertEndNode] = Editor.node(editor, insertEndPoint);
      if (insertEndNode) {
        const dom = ReactEditor.toDOMNode(editor, insertEndNode);
        // console.debug(dom.parentElement?.innerText, insertEndNode);
        calculateToolbarTop(true, dom.parentElement || dom);
      }
    } catch (error) {
      // console.error('[get dom node from slate node with error]', error);
      clearTimeout(timer);
    }
  }, 1);
};
