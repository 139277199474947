import React, { createContext, useMemo, useContext, useReducer } from 'react';
import { EditorKit, EditorRaw } from '@hkgai/slate-plugin/dist/lib/core';
import { Location, Node, Range } from '@hkgai/slate-plugin/dist/lib/delta';
import { insertPlainText, insertSlateNodes } from '@/views/writing/components/doc-editor/util';

interface State {
  editor?: EditorRaw; // doc编辑器实例
  editorKit?: EditorKit; // doc编辑器封装实例
  editableRef?: React.RefObject<HTMLDivElement>; // doc编辑器的html元素实例

  setEditor?: (p: EditorRaw) => void;
  setEditorKit?: (p: EditorKit) => void;
  setEditableRef?: (p: React.RefObject<HTMLDivElement>) => void;

  insertContent?: (texts: string | Node[], at: Location, renderNow?: boolean) => Range;
}

const initialState = {};

type Action =
  | {
      type: 'SET_EDITOR';
      payload: EditorRaw;
    }
  | {
      type: 'SET_EDITOR_KIT';
      payload: EditorKit;
    }
  | {
      type: 'SET_EDITABLE_REF';
      payload: React.RefObject<HTMLDivElement>;
    };

const WritingContext = createContext<State>(initialState);
WritingContext.displayName = 'WritingContext';

const contextReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_EDITOR': {
      return {
        ...state,
        editor: action.payload,
      };
    }
    case 'SET_EDITOR_KIT': {
      return {
        ...state,
        editorKit: action.payload,
      };
    }
    case 'SET_EDITABLE_REF': {
      return {
        ...state,
        editableRef: action.payload,
      };
    }
    default:
      return state;
  }
};

export const WritingProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(contextReducer, initialState);
  const setEditor = (payload: EditorRaw): void => dispatch({ type: 'SET_EDITOR', payload });
  const setEditorKit = (payload: EditorKit): void => dispatch({ type: 'SET_EDITOR_KIT', payload });
  const setEditableRef = (payload: React.RefObject<HTMLDivElement>): void => dispatch({ type: 'SET_EDITABLE_REF', payload });
  const insertContent = (content: string | Node[], at: Location, renderNow = false) => {
    const { editor } = state;
    if (!editor) {
      throw Error("[writing context] editor hasn't initialized yet.");
    }
    if (typeof content === 'string') {
      return insertPlainText(editor, content, at, renderNow);
    }
    return insertSlateNodes(editor, content, at);
  };

  const value = useMemo(
    () => ({
      ...state,
      setEditor,
      setEditorKit,
      setEditableRef,
      insertContent,
    }),
    [state],
  );

  return <WritingContext.Provider value={value}>{children}</WritingContext.Provider>;
};

export const useWritingContext = () => useContext(WritingContext);
