import SurveyApi from '@hkgai/pb-fe-api/lib/survey_api/survey_api';
import { REQUEST_TIMEOUT, SERVICE_HOST } from '@config/constant';

import { GetSurveyReq, GetSurveyResultReq, GetSurveyResultResp, Questionnaire, SubmitSurveyReq, SubmitSurveyResp } from '@hkgai/pb-fe-api/lib/interface/survey_api';
import { interceptorRequestConfig, interceptorRequestError, interceptorResponseConfig, interceptorResponseError } from './request';

//
export const surveyApi = new SurveyApi(SERVICE_HOST, {
  timeout: REQUEST_TIMEOUT,
});

// 配置通用拦截器
surveyApi.serviceInstance.interceptors.request.use(interceptorRequestConfig, interceptorRequestError);
surveyApi.serviceInstance.interceptors.response.use(interceptorResponseConfig, interceptorResponseError);

export const getSurvey = async (params: GetSurveyReq) => {
  return (await surveyApi.GetSurvey(params)) as Promise<Questionnaire>;
};

export const submitSurvey = async (params: SubmitSurveyReq) => {
  return (await surveyApi.SubmitSurvey(params)) as Promise<SubmitSurveyResp>;
};

export const getSurveyResult = async (params: GetSurveyResultReq) => {
  return (await surveyApi.GetSurveyResult(params)) as Promise<GetSurveyResultResp>;
};
