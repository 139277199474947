import {
  IconAlignCenter,
  IconAlignLeft,
  IconAlignRight,
  IconBold,
  IconDelete,
  IconDownload,
  IconEraser,
  IconFontColors,
  IconH1,
  IconH2,
  IconH3,
  IconH4,
  IconH5,
  IconItalic,
  IconLineHeight,
  IconOrderedList,
  IconStrikethrough,
  IconUnderline,
  IconUnorderedList,
} from '@arco-design/web-react/icon';
import { IconRedo, IconUndo, IconAlignJustify, IconLineText } from '@arco-iconbox/react-hkgai-government-icon';

import {
  ALIGN_KEY,
  BOLD_KEY,
  ITALIC_KEY,
  ORDERED_LIST_KEY,
  STRIKE_THROUGH_KEY,
  STYLE_SETTING_KEY,
  FONT_SETTING_KEY,
  UNDERLINE_KEY,
  UNORDERED_LIST_KEY,
  HEADING_KEY,
  LINE_HEIGHT_CUSTOM_KEY,
  PARAGRAPH_KEY,
  CLEAR_KEY,
  DOWNLOAD_DOCX_KEY,
  DOWNLOAD_PDF_KEY,
} from '@hkgai/slate-plugin/dist/lib/plugins';

import { CustomEventOptions, DoActions, MenuItem, TOOL_MENU_GROUP_ACTIONS, TOOL_MENU_GROUP_DOS, TOOL_MENU_RESET } from '@/type/toolsbar';

const FONT_SIZE_OPTIONS = [10, 11, 12, 13, 14, 16, 18, 20, 24, 28, 32, 36, 72, 128];
const LINE_HEIGHT_OPTIONS = [1, 1.15, 1.5, 2];
const COLOR_OPTIONS = ['#222', '#999', '#F43B3D', '#F76965', '#F7BA1E', '#FADC19', '#F5319D', '#00B42A', '#3491FA', '#165DFF', '#722ED1'];
const BACKGROUND_OPTIONS = ['none', '#E5E5E5', '#FFCF8B', '#FBB0A7', '#E67269', '#FBEA57', '#FDFA94', '#7BE188', '#9FD4FD', '#F08EE6', '#C396ED'];

export const menu: MenuItem[] = [
  {
    icon: <IconUnorderedList />,
    key: HEADING_KEY,
    menuWidth: 180,
    options: {
      commonConfig: [
        {
          icon: <IconLineText />,
          key: PARAGRAPH_KEY,
          value: 'Body Text',
        },
        {
          icon: <IconH1 />,
          key: `${HEADING_KEY}.h1`,
          value: 'Heading 1',
        },
        {
          icon: <IconH2 />,
          key: `${HEADING_KEY}.h2`,
          value: 'Heading 2',
        },
        {
          icon: <IconH3 />,
          key: `${HEADING_KEY}.h3`,
          value: 'Heading 3',
        },
        {
          icon: <IconH4 />,
          key: `${HEADING_KEY}.h4`,
          value: 'Heading 4',
        },
        {
          icon: <IconH5 />,
          key: `${HEADING_KEY}.h5`,
          value: 'Heading 5',
        },
        {
          icon: <IconUnorderedList />,
          key: UNORDERED_LIST_KEY,
          value: 'Bulleted List',
        },
      ],
    },
  },
  {
    icon: <IconAlignLeft />,
    key: ALIGN_KEY,
    menuWidth: 180,
    options: {
      commonConfig: [
        {
          icon: <IconAlignLeft />,
          key: `${ALIGN_KEY}.left`,
          value: 'Align Left',
        },
        {
          icon: <IconAlignCenter />,
          key: `${ALIGN_KEY}.center`,
          value: 'Align Center',
        },
        {
          icon: <IconAlignRight />,
          key: `${ALIGN_KEY}.right`,
          value: 'Align Right',
        },
        {
          icon: <IconAlignJustify />,
          key: `${ALIGN_KEY}.justify`,
          value: 'Align Justify',
        },
      ],
    },
  },
  {
    icon: <IconLineHeight />,
    key: LINE_HEIGHT_CUSTOM_KEY,
    menuWidth: 72,
    options: {
      commonConfig: LINE_HEIGHT_OPTIONS.map(size => ({
        key: `${LINE_HEIGHT_CUSTOM_KEY}.${size}`,
        value: size,
      })),
    },
  },
  {
    icon: <IconBold />,
    key: BOLD_KEY,
  },
  {
    icon: <IconStrikethrough />,
    key: STRIKE_THROUGH_KEY,
  },
  {
    icon: <IconItalic />,
    key: ITALIC_KEY,
  },
  {
    icon: <IconUnderline />,
    key: UNDERLINE_KEY,
  },
  {
    icon: <IconOrderedList />,
    key: ORDERED_LIST_KEY,
  },
  {
    key: FONT_SETTING_KEY,
    options: {
      fontSizeMap: FONT_SIZE_OPTIONS.map(size => ({
        key: `${FONT_SETTING_KEY}.fontSize:${size}px`,
        value: size,
      })),
    },
  },
  {
    key: STYLE_SETTING_KEY,
    icon: <IconFontColors />,
    options: {
      colorMap: COLOR_OPTIONS.map(color => ({
        key: `${STYLE_SETTING_KEY}.color:${color}`,
        value: color,
      })),
      backgroundMap: BACKGROUND_OPTIONS.map(color => ({
        key: `${STYLE_SETTING_KEY}.background:${color}`,
        value: color,
      })),
      reset: {
        key: `${STYLE_SETTING_KEY}.${TOOL_MENU_RESET}`,
        value: TOOL_MENU_RESET,
      },
    },
  },
  {
    icon: <IconEraser />,
    key: CLEAR_KEY,
  },
  {
    key: TOOL_MENU_GROUP_DOS,
    options: {
      commonConfig: [
        {
          icon: <IconRedo />,
          key: DoActions.REDO,
        },
        {
          icon: <IconUndo />,
          key: DoActions.UNDO,
        },
      ],
    },
  },
  {
    key: TOOL_MENU_GROUP_ACTIONS,
    options: {
      commonConfig: [
        {
          icon: <IconDelete />,
          key: CustomEventOptions.DELETE,
          iconColor: '#CB272D',
        },
        // {
        //   icon: <IconCopy />,
        //   key: CustomEventOptions.COPY,
        // },
        // {
        //   icon: <IconDownload />,
        //   key: DOWNLOAD_DOCX_KEY,
        // },
      ],
    },
  },
  {
    icon: <IconDownload />,
    key: CustomEventOptions.DOWNLOAD,
    options: {
      commonConfig: [
        {
          key: DOWNLOAD_DOCX_KEY,
          value: 'Download Docx',
        },
        {
          key: DOWNLOAD_PDF_KEY,
          value: 'Download PDF',
        },
      ],
    },
  },
];
