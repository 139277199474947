import { Empty, Select, Trigger, Typography } from '@arco-design/web-react';
import { useModel } from '@modern-js/runtime/model';
import { FC, useMemo } from 'react';

import { IconHistory } from '@arco-iconbox/react-hkgai-government-icon';
import { IconX } from '@arco-iconbox/react-hkgai-fe-icon';
import PromptHistoryStore from '@/store/promptHistoryStore';
import useLocale from '@/hooks/useLocale';
import { WritingType } from '@/type/ui';

const PromptSelect: FC<{ prompts: string[]; onChange: (item: any) => void; onDelete?: (prompt: string) => void }> = ({ prompts = [], onChange, onDelete }) => {
  const locale = useLocale();
  return (
    <Select
      onChange={onChange}
      triggerElement={
        <div className="flex items-center gap-2 cursor-pointer">
          <IconHistory width={'18px'} height={'18px'} />
          <span className="text-sm select-none">{locale['prompt.history.trigger.text']}</span>
        </div>
      }
      triggerProps={{ autoAlignPopupWidth: false, position: 'br' }}
      dropdownMenuClassName="min-w-[480px] max-w-[480px]"
      notFoundContent={<Empty description={locale['prompt.history.select.empty']} icon={<div></div>} imgSrc="" />}
    >
      {prompts.map(str => (
        <Select.Option key={str} value={str}>
          <Trigger
            popup={() => (
              <IconX
                className="cursor-pointer"
                onClick={e => {
                  onDelete?.(str);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            )}
            position="right"
            popupAlign={{ right: -12 }}
          >
            <span>
              <Typography.Ellipsis showTooltip rows={1} className="pr-2">
                {str}
              </Typography.Ellipsis>
            </span>
          </Trigger>
        </Select.Option>
      ))}
    </Select>
  );
};

export const AIWritingPromptHistory: FC<{ onChange: (item: any) => void }> = ({ onChange }) => {
  const [{ writingPromptHistory }, { removeAIWritingPromptHistory }] = useModel(PromptHistoryStore);
  const showPrompts = useMemo(() => {
    return writingPromptHistory.slice(0, 20);
  }, [writingPromptHistory]);
  return <PromptSelect prompts={showPrompts} onChange={onChange} onDelete={prompt => removeAIWritingPromptHistory(prompt)} />;
};

export const ToolbarPromptHistory: FC<{ onChange: (item: any) => void; writingType?: WritingType }> = ({ onChange, writingType }) => {
  const [{ toolbarPromptHistory }, { removeToolbarPromptHistory }] = useModel(PromptHistoryStore);
  const showPrompts = useMemo(() => {
    if (writingType) {
      return toolbarPromptHistory[writingType]?.slice?.(0, 20) || [];
    }
    return [];
  }, [toolbarPromptHistory, writingType]);
  return <PromptSelect prompts={showPrompts} onChange={onChange} onDelete={prompt => removeToolbarPromptHistory({ writingType, prompt })} />;
};
