import { Form } from '@arco-design/web-react';
import { Question, QuestionGroup, QuestionType } from '@hkgai/pb-fe-api/lib/interface/survey_api';
import { FC, Fragment } from 'react';
import RenderQuestion from '../question';

interface RenderQuestionGroupsProps {
  questionGroups?: QuestionGroup[];
  fromValues?: any;
}

const RenderQuestionGroups: FC<RenderQuestionGroupsProps> = ({ questionGroups = [], fromValues = {} }) => {
  //  字怎符转数组

  return (
    <>
      {questionGroups?.map((questionGroup: QuestionGroup) => {
        const isGroups = questionGroup?.questionSubGroups?.length;
        return (
          <Form.Item key={questionGroup.questionGroupId} label={questionGroup.questionGroupDesc} className="question-group-desc">
            {isGroups ? (
              <>
                {(questionGroup?.questionSubGroups || [])?.map((questionSubGroup: QuestionGroup) => (
                  <Fragment key={questionGroup.questionGroupId}>
                    {questionSubGroup?.questions?.map(question => (
                      <RenderQuestion
                        key={question.questionId}
                        question={question}
                        questionType={question.questionType || QuestionType.SINGLECHOICE}
                        userAnswer={fromValues[question?.questionId || '']?.userAnswer || {}}
                      />
                    ))}
                    {(questionSubGroup?.questionSubGroups || [])?.length && (
                      <RenderQuestionGroups questionGroups={questionSubGroup?.questionSubGroups || []} fromValues={fromValues} />
                    )}
                  </Fragment>
                ))}
              </>
            ) : (
              <>
                {questionGroup?.questions?.map((question: Question) => {
                  return (
                    <RenderQuestion
                      key={question.questionId}
                      question={question}
                      questionType={question.questionType || QuestionType.SINGLECHOICE}
                      userAnswer={fromValues[`${question?.questionId}_${question?.questionType}`]?.userAnswer || {}}
                    />
                  );
                })}
              </>
            )}
          </Form.Item>
        );
      })}
    </>
  );
};

export default RenderQuestionGroups;
