import { IconDev, IconHkCopilot, IconUat } from '@arco-iconbox/react-hkgai-government-icon';
import React, { CSSProperties, FC } from 'react';

type DomainLogoMap = {
  [key: string]: React.ReactNode;
};
const iconStyle = { width: '40px' };
const domainLogoMap: DomainLogoMap = {
  localhost: <IconDev style={iconStyle} />,
  'copilot.hkgai.net': <IconDev style={iconStyle} />,
  'copilot-test.hkgai.org': <IconUat style={iconStyle} />,
  'copilot.hkgai.org': <></>,
};
const EnvSuffix = React.memo(() => {
  const domain = window.location.hostname;
  const logo = domainLogoMap[domain];
  return <>{logo ? logo : <></>}</>;
});

interface LogoProps {
  showEnvSuffix?: boolean;
  suffixStyle?: CSSProperties;
}

const Logo: FC<LogoProps> = ({ showEnvSuffix = true, suffixStyle }) => {
  return (
    <div className="flex items-end ">
      <div style={{ height: '36px' }}>
        <IconHkCopilot style={{ width: '129px', height: '36px' }} />
      </div>
      <div className="flex items-end" style={{ height: '36px', position: 'relative', bottom: '4px', ...suffixStyle }}>
        {showEnvSuffix && <EnvSuffix />}
      </div>
    </div>
  );
};

export default Logo;
