/* eslint-disable no-useless-call */
/* eslint-disable no-param-reassign */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Message, Modal } from '@arco-design/web-react';
import defaultLocale from '../locale';
import { ErrorCode } from './errno';

export enum ContentType {
  XHTML = 'application/xhtml+xml', // XHTML格式
  XML = 'application/xml', //  XML数据格式
  ATOM_XML = 'application/atom+xml', // Atom XML聚合格式
  JSON = 'application/json', //  JSON数据格式
  PDF = 'application/pdf', // pdf格式
  WORD = 'application/msword;charset=utf-8', //  Word文档格式
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8', //  excel文档格式
  STREAM = 'application/octet-stream', //  二进制流数据（如常见的文件下载）
  FORM_URLENCODED = 'application/x-www-form-urlencoded', //  <form encType=””中默认的encType，form表单数据被编码为key/
  FORM_DATA = 'multipart/form-data', //  需要在表单中进行文件上传时，就需要使用该格式
  ZIP = 'application/zip',
}

// 转换对象的小驼峰为下划线
function camelToSnake(obj: Record<string, any>): Record<string, any> {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => camelToSnake(item));
  }

  const snakeObj: Record<string, any> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const snakeKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
      snakeObj[snakeKey] = camelToSnake(obj[key]);
    }
  }
  return snakeObj;
}

// 转换对象的下划线为小驼峰
function snakeToCamel(obj: Record<string, any>): Record<string, any> {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => snakeToCamel(item));
  }

  const camelObj: Record<string, any> = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = key.replace(/_([a-z])/g, function (match, letter) {
        return letter.toUpperCase();
      });
      camelObj[camelKey] = snakeToCamel(obj[key]);
    }
  }
  return camelObj;
}

export interface HttpRequester {
  request: <T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig) => Promise<R>;
}

export interface RequestConfig extends AxiosRequestConfig {
  [additionalField: string]: any;
}

export type ServicePromise<T = any> = Promise<T>;

export interface ResponseObject<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: RequestConfig;
  request?: any;
}

/**
 * axios 的 forEach 方法，用来通用地遍历数组或对象结构
 * @param {被遍历的对象或数组或值} obj
 * @param {回调} fn
 */
const forEach = (obj: any, fn: (item: any, key: string | number, self: any) => void) => {
  if (obj === null || typeof obj === 'undefined') {
    return;
  }
  if (typeof obj !== 'object') {
    obj = [obj];
  }
  if (Array.isArray(obj)) {
    for (let i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  } else {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        fn.call(null, obj[key], key, obj);
      }
    }
  }
};

export const merge = (...params: object[]) => {
  const result = {} as any;
  function assignValue(val: any, key: string | number) {
    if (typeof result[key] === 'object' && typeof val === 'object') {
      result[key] = merge(result[key], val);
    } else {
      result[key] = val;
    }
  }
  for (let i = 0, l = params.length; i < l; i++) {
    forEach(params[i], assignValue);
  }
  return result;
};

const locale = (key: string): string => {
  const lang = localStorage.getItem('arco-lang') || 'en-US';
  return defaultLocale[lang][key];
};

class HttpServer {
  serviceHost: string;

  config: RequestConfig;

  public serviceInstance: AxiosInstance;

  constructor(serviceHost: string, config?: RequestConfig) {
    this.serviceHost = serviceHost;
    this.config = config || {};
    this.serviceInstance = axios.create({ baseURL: serviceHost, ...config });
  }

  protected request<T = any>(config: RequestConfig): ServicePromise<T> {
    return this.serviceInstance.request(merge(this.config, config));
  }

  head<T = any>(url: string, config: RequestConfig = {}): ServicePromise<T> {
    return this.request(merge(config, { method: 'head', url }));
  }

  get<T = any>(url: string, params?: any, config: RequestConfig = {}): ServicePromise<T> {
    return this.request(merge(config, { method: 'get', url, params }));
  }

  post<T = any>(url: string, data?: any, config: RequestConfig = {}): ServicePromise<T> {
    return this.request(merge(config, { method: 'post', url, data }));
  }

  options<T = any>(url: string, config: RequestConfig = {}): ServicePromise<T> {
    return this.request(merge(config, { method: 'options', url }));
  }

  put<T = any>(url: string, data?: any, config: RequestConfig = {}): ServicePromise<T> {
    return this.request(merge(config, { method: 'put', url, data }));
  }

  delete<T = any>(url: string, params?: any, config: RequestConfig = {}): ServicePromise<T> {
    return this.request(merge(config, { method: 'delete', url, params }));
  }
}

// 封装网络请求
export default HttpServer;

// 网络请求的请求头 拦截处理公共逻辑
export const interceptorRequestConfig = (config: AxiosRequestConfig | any) => {
  //
  const Lang = localStorage.getItem('arco-lang') || 'en-US';
  config.headers = {
    ...config.headers,
    'X-App-Id': 2,
    'Content-Type': ContentType.JSON,
    'X-Acc-Lang': Lang,
  };

  config.params = {
    ...config.params,
    app_id: 2,
  };

  //   `transformResponse` 在传递给 then/catch 前，允许修改响应数据
  config.transformResponse = (...args: any) => {
    const response = snakeToCamel(JSON.parse(args[0]));
    return response;
  };
  // `transformRequest` 允许在向服务器发送前，修改请求数据
  config.transformRequest = (...args: any) => {
    const request = camelToSnake(args[0]);
    return JSON.stringify(request);
  };

  return config;
};

const redirectToLogin = (message = '') => {
  const redirectUri = location.pathname + location.search;
  //   pathname?search
  if (window.location.href.indexOf('/login') >= 0) {
    return;
  }
  message && Message.error(message);
  window.location.replace(`/login?redirectUrl=${encodeURI(redirectUri)}`);
};

// 记录是否已经弹出过鉴权失败的 Modal
// let authFailedModalShown = false;
// 网络请求的响应体 拦截处理公共逻辑

let authFailedModalShown = false;

export const interceptorResponseConfig = (response: AxiosResponse | any) => {
  const { data } = response;
  console.log('dataCode:', data.code);
  if (data.code !== ErrorCode.SUCCESS) {
    if (data.code === ErrorCode.NOT_LOGIN && response.config.url === '/passport/api/get_user_info' && response.config.baseURL === '/') {
      redirectToLogin();
      return Promise.reject(data);
    }

    switch (data.code) {
      case ErrorCode.NOT_LOGIN: // 未鉴权
        localStorage.clear();
        sessionStorage.clear();
        redirectToLogin();
        break;
      case ErrorCode.H_ERR_USER_BLOCKED: // 禁用
        redirectToLogin(locale('login_user_blocked'));
        break;
      case ErrorCode.H_ERR_USER_CHANGE_CYCLE:
        // localStorage.setItem('PASSWORD_STATUS', 'NeedsChange');
        if (!authFailedModalShown && window.location.pathname.indexOf('/pwd') < 0) {
          authFailedModalShown = true;
          Modal.info({
            autoFocus: false,
            title: locale('exit_modal_password_expired_content'),
            okText: locale('exit_modal_password_expired_button'),
            onOk: () => {
              authFailedModalShown = false;
              window.open('/pwd/reset');
            },
            afterClose: () => {
              authFailedModalShown = false;
            },
          });
        }
        break;
      default:
        break;
    }
    console.log('Promise.reject', data);

    return Promise.reject(data);
  }

  return data;
};

// 网络请求的响应中 拦截处理失败的case
export const interceptorResponseError = (error: AxiosError) => {
  if (error?.response) {
    console.log(`错误信息:${error.response.status}`);
  }
  return Promise.reject(error);
};
// 网络请求的请求头 拦截处理失败逻辑
export const interceptorRequestError = (error: AxiosError) => {
  return Promise.reject(error);
};

export const encodedParams = (params: any) => {
  return Object.keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
};
