import './index.scss';
import { Button, Divider, Form, Message, Result, Spin } from '@arco-design/web-react';
import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { OptionType, QuestionGroup, Questionnaire, QuestionType, QuestionUserAnswer, UserAnswerType } from '@hkgai/pb-fe-api/lib/interface/survey_api';
import { useLocalStorageState } from 'ahooks';
import dayjs from 'dayjs';
import { IconIconEmpty, IconSuccess } from '@arco-iconbox/react-hkgai-government-icon';
import RenderQuestionGroups from './components/question-groups';
import { AuthUserInfo, RequireAuth } from '@/routes/auth';
import SimpleHead from '@/components/simple-head';
import { getSurvey, getSurveyResult, submitSurvey } from '@/api/survey_api';

const QUESTIONNAIRE_ID = '1879856368219852800';
// const QUESTIONNAIRE_RESULT_ID = '1880125596432863232';
const Survey = () => {
  const [form] = Form.useForm();

  const [localMessage, setLocalMessage] = useLocalStorageState<Partial<FormData>>(`LOCAL_STORAGE_SURVEY_PAPER_${QUESTIONNAIRE_ID}`, {});
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [formValues, setFormValues] = useState<Partial<FormData>>({});
  const [loading, setLoading] = useState(false);

  const [questionnaire, setQuestionnaire] = useState<Questionnaire>({});
  const [isSurveyExpired, setIsSurveyExpired] = useState(false);
  const [isSurveySubmit, setIsSurveySubmit] = useState(false);

  //  获取提交记录
  const fetchSurveyResult = async () => {
    try {
      const resp = await getSurveyResult({ questionnaireId: QUESTIONNAIRE_ID });
      if (resp) {
        setIsSurveySubmit(() => true);
      }
      console.log('fetcSurveyResult', resp);
    } catch (error) {
      console.log('error', error);
    }
  };

  //   获取问列表
  const fetchSurvey = async () => {
    setLoading(() => true);
    try {
      const resp = await getSurvey({ questionnaireId: QUESTIONNAIRE_ID });
      const expireTime = resp?.questionnaireBaseInfo?.expireTime || 0;
      if (expireTime) {
        setIsSurveyExpired(() => dayjs().isAfter(dayjs(Number(expireTime) * 1000)));
      }
      setQuestionnaire(() => resp);
    } catch (error: any) {
      console.error('fetch survey error:', error);
    } finally {
      setLoading(() => false);
    }
  };

  useLayoutEffect(() => {
    //   初始化页面数据
    const initSurvey = async () => {
      await Promise.all([fetchSurvey(), fetchSurveyResult()]);
    };
    initSurvey();
  }, []);

  //   检查是否有缓存未提交数据
  useEffect(() => {
    if (localMessage && questionnaire?.questionContent?.questionNodes?.length && !isSurveyExpired && !isSurveySubmit) {
      form.setFieldsValue(localMessage);
    }
  }, [questionnaire?.questionContent?.questionNodes?.length, isSurveyExpired, isSurveySubmit]);

  //   表单改变
  const onValuesChange = (_value: any, values: any) => {
    setFormValues(() => values);
    setLocalMessage(values);
  };

  // 题目数量
  const getTotalQuestions = (questionnaire: Questionnaire): number => {
    const countQuestionsInGroup = (group: QuestionGroup): number => {
      let count = group?.questions?.length || 0;
      if (group.questionSubGroups) {
        group.questionSubGroups.forEach(subGroup => {
          count += countQuestionsInGroup(subGroup);
        });
      }
      return count;
    };
    let totalQuestions = 0;
    (questionnaire?.questionContent?.questionNodes || []).forEach(node => {
      (node?.questionGroups || []).forEach(group => {
        totalQuestions += countQuestionsInGroup(group);
      });
    });
    return totalQuestions;
  };

  //   格式化 UserAnswers
  const transformUserAnswers = (rawData: { [key: string]: any }, questionType: QuestionType, inputText = '') => {
    if (questionType === QuestionType.SINGLECHOICE) {
      const selectedIndex = rawData?.radioVal.split('_').map((i: string) => Number(i));
      const optionType: OptionType = selectedIndex[1];
      const userAnswer = {
        inputText,
        selectedIndex: selectedIndex[0],
        userAnswerType: optionType === OptionType.SELECTIONWITHINPUTTING ? UserAnswerType.USERSELECTIONANDINPUT : UserAnswerType.USERSELECTION,
      };
      return userAnswer;
    }
    if (questionType === QuestionType.MULTICHOICE) {
      const userAnswer = (rawData.checkboxVal || []).map((item: string) => {
        const selectedIndex = item.split('_').map((i: string) => Number(i));
        const optionType: OptionType = selectedIndex[1];
        return {
          inputText: optionType === OptionType.SELECTIONWITHINPUTTING ? inputText : '',
          selectedIndex: selectedIndex[0],
          userAnswerType: optionType === OptionType.SELECTIONWITHINPUTTING ? UserAnswerType.USERSELECTIONANDINPUT : UserAnswerType.USERSELECTION,
        };
      });
      return userAnswer;
    }

    if (questionType === QuestionType.RATE) {
      const userAnswer = {
        userAnswerType: UserAnswerType.USERRATE,
        rateScore: rawData.rateVal,
      };
      const userComment = {
        inputText,
      };
      return { userAnswer, userComment };
    }
    // 默认简答题
    return {
      userAnswerType: UserAnswerType.USERINPUT,
      inputText: rawData.inputVal,
    };
  };
  //   格式化数据 QuestionUserAnswers
  const transformQuestionUserAnswers = (data: any) => {
    const result = [];
    // 遍历原始数据
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // 拆分 key 得到 questionId 和 questionType
        const [questionId, questionType] = key.split('_').map(Number);

        const questionUserAnswer: QuestionUserAnswer = {
          questionId,
          questionType,
        };
        const { inputText } = data[`${questionId}_${questionType}`];

        const { userAnswer } = data[key];
        if (questionType === QuestionType.SINGLECHOICE) {
          questionUserAnswer.singleChoiceQuestionUserAnswer = { userAnswer: transformUserAnswers(userAnswer, questionType, inputText) };
        } else if (questionType === QuestionType.MULTICHOICE) {
          questionUserAnswer.multiChoiceQuestionUserAnswer = { userAnswers: transformUserAnswers(userAnswer, questionType, inputText) };
        } else if (questionType === QuestionType.RATE) {
          questionUserAnswer.rateQuestionUserAnswer = { ...transformUserAnswers(userAnswer, questionType, inputText) };
        } else if (questionType === QuestionType.QUIZ) {
          questionUserAnswer.quizQuestionUserAnswer = { userAnswer: transformUserAnswers(userAnswer, questionType, inputText) };
        }
        result.push(questionUserAnswer);
      }
    }

    return result;
  };

  //   是否可以提交
  const isSubmitDisabled = useMemo(() => {
    return !(getTotalQuestions(questionnaire) === Object.entries(formValues).length);
  }, [questionnaire?.questionContent?.questionNodes, formValues]);

  const handleSubmit = async (values: any) => {
    setConfirmLoading(() => true);
    try {
      const questionUserAnswers = transformQuestionUserAnswers(values);
      console.log('handleSubmit transformedData===>', questionUserAnswers);
      await submitSurvey({
        questionnaireId: QUESTIONNAIRE_ID,
        questionUserAnswers,
      });
      Message.success('Can only be filled out once');
      setIsSurveySubmit(() => true);
      form.resetFields();
    } catch (error) {
      Message.error('Submission failed');
    } finally {
      setConfirmLoading(() => false);
    }
  };

  return (
    <RequireAuth>
      <>
        <SimpleHead extra={<AuthUserInfo />} style={{ background: '#fff', borderBottom: '8px solid rgba(242, 242, 242, 1)' }} />
        {/* 问卷调查未提交 且 未过期 */}
        <Spin loading={loading} block>
          {!isSurveySubmit && !isSurveyExpired && (
            <div className="survey flex flex-col">
              <h1 className="text-center py-3">{questionnaire.questionnaireBaseInfo?.questionnaireDesc}</h1>
              <div className="flex-1  overflow-x-hidden  overflow-y-auto">
                <div style={{ width: '1024px' }} className="mx-auto">
                  <Form
                    form={form}
                    layout="vertical"
                    autoComplete="off"
                    size="large"
                    className="survey-from"
                    scrollToFirstError
                    onValuesChange={onValuesChange}
                    onSubmit={handleSubmit}
                  >
                    {questionnaire?.questionContent?.questionNodes?.map((questionNode, index) => (
                      <Fragment key={questionNode.questionNodeId}>
                        {questionNode.questionGroups?.map((questionGroup: QuestionGroup) => (
                          <RenderQuestionGroups key={questionGroup.questionGroupId} questionGroups={[questionGroup]} fromValues={formValues} />
                        ))}
                        {index !== (questionnaire?.questionContent?.questionNodes || [])?.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                    <Form.Item>
                      <Button type="primary" className="survey-from-button" size="large" htmlType="submit" disabled={isSubmitDisabled} loading={confirmLoading}>
                        Submit survey
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          )}
          {/* 问卷已提交 */}
          {isSurveySubmit && (
            <div className="survey-submit-content">
              <Result title="Submitted successfully" icon={<IconSuccess style={{ width: '48px', height: '48px' }} />} status={null} />
            </div>
          )}
          {/* 问卷已过期 */}
          {isSurveyExpired && (
            <div className="survey-expired-content">
              <Result title="The questionnaire has expired" icon={<IconIconEmpty style={{ width: '250px', height: '200px' }} />} status={null} />
            </div>
          )}
        </Spin>
      </>
    </RequireAuth>
  );
};

export default Survey;
